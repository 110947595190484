import i18n from "@/plugins/i18n/i18n";
import { AttendanceType } from "./GemaMember";
import { RegistrationDay, RegistrationStatus } from "./Registration";
import { RepresentationFunction } from "./Representation";

export * from "./Representation";
export * from "./Registration";
export * from "./GemaMember";

// Query params for views.
export enum UserListViews {
  allMembers = "allMembers",
  attendance = "attendance",
  preVoting = "preVoting",
  digitalParticipation = "digitalParticipation",
  digitalRightsTransfer = "digitalRightsTransfer",
  presentRightsTransfer = "presentRightsTransfer",
  digitalSubstitution = "digitalSubstitution",
  presentSubstitution = "presentSubstitution",
  memberFestival = "memberFestival",
  roadshow = "roadshow",
  technicalTraining = "technicalTraining",
  curiaConsultationHourLyricist = "curiaConsultationHourLyricist",
  curiaConsultationHourComposer = "curiaConsultationHourComposer",
  curiaConsultationHourPublisher = "curiaConsultationHourPublisher",
  occupationalGroupGatheringLyricist = "occupationalGroupGatheringLyricist",
  occupationalGroupGatheringComposer = "occupationalGroupGatheringComposer",
  occupationalGroupGatheringPublisher = "occupationalGroupGatheringPublisher",
}

export const userListViewLabels = (): Record<UserListViews, string> => {
  return {
    [UserListViews.allMembers]: i18n
      .t("gema.table.searchBar.views.allMembers")
      .toString(),
    [UserListViews.attendance]: i18n
      .t("gema.table.searchBar.views.attendance")
      .toString(),
    [UserListViews.preVoting]: i18n
      .t("gema.table.searchBar.views.preVoting")
      .toString(),
    [UserListViews.digitalParticipation]: i18n
      .t("gema.table.searchBar.views.digitalParticipation")
      .toString(),
    [UserListViews.digitalRightsTransfer]: i18n
      .t("gema.table.searchBar.views.digitalRightsTransfer")
      .toString(),
    [UserListViews.presentRightsTransfer]: i18n
      .t("gema.table.searchBar.views.presentRightsTransfer")
      .toString(),
    [UserListViews.digitalSubstitution]: i18n
      .t("gema.table.searchBar.views.digitalSubstitution")
      .toString(),
    [UserListViews.presentSubstitution]: i18n
      .t("gema.table.searchBar.views.presentSubstitution")
      .toString(),
    // Events
    [UserListViews.memberFestival]: i18n
      .t("gema.sideEvent.memberFestival")
      .toString(),
    [UserListViews.roadshow]: i18n.t("gema.sideEvent.roadshow").toString(),
    [UserListViews.technicalTraining]: i18n
      .t("gema.sideEvent.technicalTraining")
      .toString(),
    [UserListViews.curiaConsultationHourPublisher]: i18n
      .t("gema.sideEvent.curiaConsultationHourPublisher")
      .toString(),
    [UserListViews.curiaConsultationHourComposer]: i18n
      .t("gema.sideEvent.curiaConsultationHourComposer")
      .toString(),
    [UserListViews.curiaConsultationHourLyricist]: i18n
      .t("gema.sideEvent.curiaConsultationHourLyricist")
      .toString(),
    [UserListViews.occupationalGroupGatheringPublisher]: i18n
      .t("gema.sideEvent.occupationalGroupGatheringPublisher")
      .toString(),
    [UserListViews.occupationalGroupGatheringComposer]: i18n
      .t("gema.sideEvent.occupationalGroupGatheringComposer")
      .toString(),
    [UserListViews.occupationalGroupGatheringLyricist]: i18n
      .t("gema.sideEvent.occupationalGroupGatheringLyricist")
      .toString(),
  };
};

export const attendanceTypeLabels = (): Record<AttendanceType, string> => {
  return {
    [AttendanceType.IN_PERSON]: i18n
      .t("gema.attendanceType.inPerson")
      .toString(),
    [AttendanceType.DIGITAL]: i18n.t("gema.attendanceType.digital").toString(),
    [AttendanceType.PRE_VOTE]: i18n.t("gema.attendanceType.preVote").toString(),
    [AttendanceType.BY_REPRESENTATIVE_IN_PERSON]: i18n
      .t("gema.attendanceType.repInPerson")
      .toString(),
    [AttendanceType.BY_REPRESENTATIVE_DIGITAL]: i18n
      .t("gema.attendanceType.repDigital")
      .toString(),
  };
};

export const registrationStatusLabels = (): Record<
  RegistrationStatus,
  string
> => {
  return {
    [RegistrationStatus.CONFIRMED]: i18n
      .t("gema.registrationStatus.confirmed")
      .toString(),
    [RegistrationStatus.PENDING]: i18n
      .t("gema.registrationStatus.pending")
      .toString(),
    [RegistrationStatus.DENIED]: i18n
      .t("gema.registrationStatus.denied")
      .toString(),
    [RegistrationStatus.REPRESENTING]: i18n
      .t("gema.registrationStatus.representing")
      .toString(),
    [RegistrationStatus.NOT_REGISTERED]: i18n
      .t("gema.registrationStatus.notRegistered")
      .toString(),
  };
};
export const attendanceDayLabels = (): Record<RegistrationDay, string> => {
  return {
    [RegistrationDay.ONE]: i18n.t("gema.registrationDay.dayOne").toString(),
    [RegistrationDay.TWO_AND_THREE]: i18n
      .t("gema.registrationDay.dayTwoAndThree")
      .toString(),
  };
};

export const representationCapacityLabels = (): Record<
  RepresentationFunction,
  string
> => {
  return {
    [RepresentationFunction.OWNER]: i18n
      .t("gema.representationFunction.owner")
      .toString(),
    [RepresentationFunction.PROXY]: i18n
      .t("gema.representationFunction.proxy")
      .toString(),
  };
};

export interface RegistrationExportResponse {
  csvString: string;
}

export interface RegistrationStatsResponse {
  usersVoted: number;
  totalVotes: number;
  abstainedVotes: number;
}

export interface Question {
  id: string;
  wahlergruppen: Wahlergruppen;
  type: string;
  title: string;
  question: string;
  link: string;
  answers: QuestionAnswer[];
  minimumVotes: number;
  maximumVotes: number;
  abstainedVotes: number;
  tenantId: string;
}

export interface QuestionAnswer {
  answerId: string;
  voteCount: number;
  value: string;
}
export enum Wahlergruppen {
  Komponisten = "Komponisten",
  Textdichter = "Textdichter",
  Verleger = "Verleger",
}

export interface ResetPasswordResponse {
  timestamp: string;
  newPassword: string;
}
