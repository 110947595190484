import { GemaMember } from "../models";
import {
  AttendanceType,
  DelegateStatus,
  MemberStatus,
  OccupationalGroup,
} from "./GemaMember";
import { Representation } from "./Representation";

export interface RegistrationResponse {
  member: GemaMember;
  registration: RegistrationInfo;
}

export interface RegistrationInfo {
  id: string | null;
  userId: string | null;
  attendanceType: AttendanceType | null;
  attendanceTypePending: AttendanceType | null;
  days: RegistrationDay | null;
  status: RegistrationStatus | null;
  representation: Representation | null;
  createdTimestamp: string | null; //Instant
  lastEditedTimestamp: string | null; //Instant
  termsAcceptedTimestamp: string | null; //Instant
  lastEditor: string | null;
}

export enum RegistrationDay {
  ONE = "ONE",
  TWO_AND_THREE = "TWO_AND_THREE",
}

export enum RegistrationStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  REPRESENTING = "REPRESENTING",
  NOT_REGISTERED = "NOT_REGISTERED",
}

export interface RegistrationMember {
  serialNumber: number;
  memberNumber: string;
  memberStatus: MemberStatus;
  firstName: string;
  lastName: string;
  birthDate: string | null;
  email: string | null;
  group: OccupationalGroup | null;
  delegate: DelegateStatus | null;
  publisherName: string;
}
