





































































































































import { RouteName } from "@/router/types";
import { Namespace } from "@/store/types";
import { Vue, Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  AttendanceType,
  attendanceTypeLabels,
  OccupationalGroup,
  RegistrationStatus,
  registrationStatusLabels,
} from "../Registrations/@types";
import {
  GemaMemberRecordRegistration,
  GemaMemberSearchAction,
  GemaMemberSearchGetter,
  SubmitSearchPayload,
} from "./store/types";

@Component({})
export default class GemaMemberSearch extends Vue {
  formValid = false;
  rules = {
    required: (value: string) => !!value || "Required.",
  };

  OccupationalGroup = OccupationalGroup;

  searchsubmitted = false;
  searchTerm = "";

  @Getter(`${Namespace.GemaMemberSearch}/${GemaMemberSearchGetter.Results}`)
  results!: GemaMemberRecordRegistration[] | null;

  @Getter(`${Namespace.GemaMemberSearch}/${GemaMemberSearchGetter.SearchTerm}`)
  currentSearchTerm!: string;

  @Action(`${Namespace.GemaMemberSearch}/${GemaMemberSearchAction.Submit}`)
  submit!: (
    payload: SubmitSearchPayload
  ) => Promise<GemaMemberRecordRegistration[] | null>;

  mounted(): void {
    if (this.currentSearchTerm !== "" && this.searchTerm === "") {
      this.searchTerm = this.currentSearchTerm;
    }
  }

  async submitSearch(exactSearch: boolean): Promise<void> {
    if (this.formValid) {
      this.searchsubmitted = true;
      await this.submit({
        searchTerm: this.searchTerm,
        exactSearch: exactSearch,
      });
      this.searchsubmitted = false;
    }
  }

  showRegistrationDetails(registrationId: string | null): void {
    if (registrationId) {
      this.$router.push({
        name: RouteName.RegistrationsSingle,
        params: {
          registrationId: registrationId,
          routeName: RouteName.Accreditation,
        },
      });
    }
  }

  showNewRegistration(memberNumber: string): void {
    this.$router.push({
      name: RouteName.RegistrationsSingle,
      params: {
        memberNumber: memberNumber,
        registrationId: "new",
        routeName: RouteName.Accreditation,
      },
    });
  }

  registrationStatusLabel(status: RegistrationStatus | null): string {
    if (status) {
      const labels = registrationStatusLabels();
      return labels[status];
    }
    return "";
  }

  attendanceTypeLabel(type: AttendanceType | null): string {
    if (type) {
      const labels = attendanceTypeLabels();
      return labels[type];
    }
    return "TBD";
  }
}
