export interface GemaMemberResponse {
  serialNumber: number;
  bpid: string;

  firstName: string;
  lastName: string;
  memberNumber: string;
  memberStatus: MemberStatus | null;
  delegate: DelegateStatus | null;
  group: OccupationalGroup | null;

  email: string | null;
  birthDate: string | null;
  attendanceType: AttendanceType | null;
  sideEventAttendance: SideEventAttendance | null;

  publisherName: string | null;
  kaStreet: string | null;
  kaPlz: string | null;
  kaCity: string | null;
  kaCountryIso: string | null;
  kaEpost: string | null;
  portalPdf: string | null;

  publisherWithoutVotingPermission: boolean;
  kooptiert: boolean;
  rn: boolean;
  portalOpened: boolean;
  digitalVirtuos: boolean;
}

export type SideEventAttendance = Record<SideEventOption, boolean> & {
  mtChosenOccupationalGroup: OccupationalGroup | null;
  email: string;
  infoEvent1: number | null;
  infoEvent2: number | null;
  infoEvent3: number | null;
};

export enum SideEventOption {
  memberFestival = "memberFestival",
  roadshow = "roadshow",
  technicalTraining = "technicalTraining",
  curiaConsultationHour = "curiaConsultationHour",
  occupationalGroupGathering = "occupationalGroupGathering",
}

export enum MemberStatus {
  O = "O",
  A = "A",
  G = "G",
}

export enum OccupationalGroup {
  T = "T",
  K = "K",
  V = "V",
  MT = "MT",
  G = "G",
}

export enum DelegateStatus {
  NONE = "",
  D = "D",
  SD = "SD",
}

export enum AttendanceType {
  IN_PERSON = "IN_PERSON",
  DIGITAL = "DIGITAL",
  PRE_VOTE = "PRE_VOTE",
  BY_REPRESENTATIVE_IN_PERSON = "BY_REPRESENTATIVE_IN_PERSON",
  BY_REPRESENTATIVE_DIGITAL = "BY_REPRESENTATIVE_DIGITAL",
}
