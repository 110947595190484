









import { Vue, Component, Watch } from "vue-property-decorator";
import { RouteName } from "@/router/types";
import GemaMemberSearch from "@/components/GemaMemberSearch/GemaMemberSearch.vue";
import PageTemplate from "@/components/PageTemplate.vue";

@Component({
  components: {
    GemaMemberSearch,
    PageTemplate,
  },
})
export default class Accreditation extends Vue {
  RouteName = RouteName;
  isTopLevelRoute = true;

  created() {
    this.routeChange();
  }

  @Watch("$route")
  routeChange() {
    this.isTopLevelRoute =
      this.$router.currentRoute?.name === RouteName.Accreditation || false;
  }
}
