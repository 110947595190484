export interface Representation {
  noVotingPermission: boolean;
  representativeUserId: string;
  representedUserId: string;

  representativeMemberId: string;
  representedMemberId: string;

  representativeFirstName: string;
  representativeLastName: string;
  representativeEmail: string;
  representativeBirthDate: string; // TODO: Confirm date response format

  function: RepresentationFunction | null;

  conflictOfInterestIsUser: boolean;
  conflictOfInterestIsUserRepresentsInterests: boolean;
  conflictOfInterestComment: string;

  type: RepresentationType | null;
}

export enum RepresentationFunction {
  OWNER = "OWNER",
  PROXY = "PROXY",
}

export enum RepresentationType {
  REP_IN_PERSON = "REP_IN_PERSON",
  REP_DIGITAL = "REP_DIGITAL",
  EX_IN_PERSON = "EX_IN_PERSON",
  EX_DIGITAL = "EX_DIGITAL",
}
